<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('edit_employee')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-row>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="editedData.empName"
                                           :label="$t('employee_name')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="editedData.empAddress"
                                           :label="$t('employee_address')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field v-model="editedData.empEmail"
                                          :label="$t('employee_email')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field v-model="editedData.empPhone"
                                          :label="$t('employee_phone')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>



                        <v-col cols="12" md="6">

                            <v-autocomplete :items="currentEmployeeTypes"
                                            item-value="id"
                                            item-text="value"
                                            dense
                                          outlined
                                            v-model="editedData.empType"
                                            :label="$t('employee_type')"
                                            required></v-autocomplete>

                        </v-col>

                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    // import { required } from "vuelidate/lib/validators";
    import axios from "axios";

    export default {
        name: "editEmployeeForm",
        props: ["success", "eserviceID"],
        data() {
            return {
                countries: [],
                state: [],
                province: [],
                show: false,
                updated: false,
                editedData: {

                },
                currentEmployeeTypes: [
                    { id: "Permanent", value: "Permanent" },
                    { id: "Temporary", value: "Temporary" },
                ],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {
        },
        destroyed() { },
        mounted() {
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                console.log('serviceID', this.eserviceID);
                axios.get(
                    `Employee/GetEmployeeDetailByIDAsync/${this.eserviceID}`
                ).then(response => {
                    this.editedData = response.data;
                });

                //axios.get(`Airport/GetDomesticPassengerIDAsync/${this.serviceID}`).then(response=>{
                //    this.editedData = response.data;

                //});
            },
            submit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Employee/UpdateEmployeeDetailAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.servicesData.data = response.data
                            this.servicesData.message = "Employee updated Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                        this.dialogueClose()
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.vehicleType = "";
                this.editedData.vehicleCategory = "";
            }
        }
    };
</script>

<style scoped>
</style>