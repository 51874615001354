<template>
    <div>
        <div v-if="isAssociation">
            <AssociationDashboard></AssociationDashboard>
        </div>
        <div v-else>
            <v-container fluid class="ml-10" align="center">
                <v-sheet class="mt-10" color="white" align="center" tile>
                    <v-row class="fill-height" align="center" justify="center">
                        <v-col cols="12" sm="12">
                            <v-title class="display-1"
                                     style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;" v-if="!isNepaliLanguage">{{ airportData.airportName }}</v-title>
                            <v-title class="display-1"
                                     style="font-weight: bold; color:white; background-color:darkblue;padding:5px; border-radius:10px;" v-else>{{ airportData.airportNameNepali }}</v-title>
                            <br />
                            <p style="font-weight: bold;margin-top:5px" v-if="!isNepaliLanguage">
                                {{ airportData.branchAddress }}
                            </p>
                            <p style="font-weight: bold;margin-top:5px" v-else>
                                {{ airportData.branchAddressNepali }}
                            </p>
                        </v-col>
                    </v-row>
                </v-sheet>

                <template>
                    <p>{{ AirportName }}</p>

                    <!--If Immigration Data Miss Out-->
                    <!--<v-btn @click="insertLog"
                           small
                           color="primary"
                           dark>
                        <i class="material-icons">Insert immigration LOG</i>
                    </v-btn>-->
                    <v-row>
                        <v-col cols="12" sm="3">
                            <v-card color="#006400" elevation="5" style="height:180px;padding-right:2em">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div>
                                                    <p font style="font-size:16px; color:white;">
                                                        {{$t('total_no_of_flights_day')}}
                                                    </p>
                                                    <v-icon color="white" size="25">flight</v-icon>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content class="text-right" justify="center">
                                                <div>
                                                    <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                                      >742,824</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text"
                                                      style="font-size: 11px"
                                                      >CHANGE TO</v-list-item-subtitle
                                                    > -->
                                                    <v-list-item-subtitle class="white--text"
                                                                          style="font-size: 26px"
                                                                          id="subtitle">
                                                        {{
                              flightIncomingOutgoing.daily.TotalFlight
                                                        }}
                                                    </v-list-item-subtitle>

                                                    <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>-->
                                                    <!--<v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                                    <span class="white--text">3211</span><br />
                                                    <span class="white--text">(2.16%)</span>-->
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-card color="#FFA500" elevation="5" style="height:180px">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div>
                                                    <p style="font-size:16px; color:white;">
                                                        {{$t('total_no_of_arrival_flights_day')}}
                                                    </p>
                                                    <v-icon color="white" size="25">flight_land</v-icon>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content class="text-right">
                                                <div>
                                                    <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                                      >914,175</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text"
                                                      style="font-size: 11px"
                                                      >CHANGE TO</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text mb-5"
                                                      style="gont-size: 11px"
                                                      >PREVIOUSE MONTH</v-list-item-subtitle
                                                    > -->

                                                    <v-list-item-subtitle class="white--text"
                                                                          style="font-size: 26px"
                                                                          id="subtitle">
                                                        {{
                              flightIncomingOutgoing.daily.TotalIncoming
                                                        }}
                                                    </v-list-item-subtitle>

                                                    <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>-->
                                                    <!--<v-icon color="red" class="mt-2 mr-2">fas fa-sort-down</v-icon>
                                                    <span class="white--text">1697</span><br />
                                                    <span class="white--text">(0.12%)</span>-->
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-card color="#077dfa" elevation="5" style="height:180px">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div>
                                                    <p style="font-size:16px; color:white;">
                                                        {{$t('total_no_of_departure_flights_day')}}
                                                    </p>
                                                    <v-icon color="white" size="25">flight_takeoff</v-icon>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content class="text-right">
                                                <div>
                                                    <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                                      >415,721</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text"
                                                      style="font-size: 11px"
                                                      >CHANGE TO</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text mb-5"
                                                      style="gont-size: 11px"
                                                      >PREVIOUSE MONTH</v-list-item-subtitle
                                                    > -->
                                                    <v-list-item-subtitle class="white--text"
                                                                          style="font-size: 26px"
                                                                          id="subtitle">
                                                        {{
                              flightIncomingOutgoing.daily.TotalOutGoing
                                                        }}
                                                    </v-list-item-subtitle>

                                                    <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>-->
                                                    <!--<v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                                    <span class="white--text">1514</span><br />
                                                    <span class="white--text">(4.16%)</span>-->
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-card color="#8B0000" elevation="5" style="height:180px">
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content>
                                                <div>
                                                    <p style="font-size:16px; color:white;">
                                                        {{$t('count_of_flight_type_month')}}
                                                    </p>

                                                    <v-icon color="white" size="25">flight_class</v-icon>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-list-item three-line>
                                            <v-list-item-content class="text-right">
                                                <div>
                                                    <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                                      >274,762</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text"
                                                      style="font-size: 11px"
                                                      >CHANGE TO</v-list-item-subtitle
                                                    >
                                                    <v-list-item-subtitle
                                                      class="white--text mb-5"
                                                      style="gont-size: 11px"
                                                      >PREVIOUSE MONTH</v-list-item-subtitle
                                                    > -->
                                                    <v-list-item-subtitle class="white--text"
                                                                          style="font-size: 26px"
                                                                          id="subtitle">
                                                        {{
                              flightIncomingOutgoing.monthly.TotalFlighInMonth
                                                        }}
                                                    </v-list-item-subtitle>

                                                    <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>-->
                                                    <!--<v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                                    <span class="white--text">10</span><br />
                                                    <span class="white--text">(2.16%)</span>-->
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>

                        <v-col cols="12"
                               v-for="title in titles"
                               v-bind:key="title.link"
                               class="viewCard">
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <router-link :to="title.link">
                                        <HoverCard :cardData="title"></HoverCard>
                                        <!--<v-card :elevation="10"
            class="blue darken-1 text-center"
            height="100"
            width="100%">
        <div>
            <v-list-item three-line>
                <v-list-item-content>
                    <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                    <p id="subboxtitle">
                        {{title.name}}
                    </p>
                    <v-list-item-subtitle class="white--text header-6 mt-1">
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </div>
    </v-card>-->
                                    </router-link>
                                </template>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </template>
                <template>
                    <v-sheet class="mt-5" color="white" align="center" tile>
                        <v-row class="fill-height" align="center" justify="center">
                            <v-col cols="12" sm="12">
                                <!--button @click="$router.push('/report')"
                                        class="white--text"
                                        dark
                                        style="background-color: green; padding: 5px; border-radius: 5px; margin-right: 5px; ">
                                    General Report
                                </button>
                                <button @click="$router.push('/branch')"
                                        class="white--text"
                                        dark
                                        style="background-color: #077dfa; padding: 5px; border-radius: 5px; margin-right: 5px; ">
                                    Edit Profile
                                </button>-->
                                <!--<img class="white--text align-end"
                                src="../../../assets/Tribhuvan-International-Airport.jpg"
                                style="width: 100%; height: 550px; object-fit: fill; border:2px solid black; border-radius:10px">-->
                                <!--<img src="../../../assets/Tribhuvan-International-Airport.jpg" style="width: 100%; height: 550px; object-fit: fill;" />-->
                                <img :src="airportData.logo"
                                     style="width: 100%; height: 550px; object-fit: fill;" />
                            </v-col>
                        </v-row>
                    </v-sheet>
                </template>


                <template>
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            {{$t('monthly_tourist_arrival_departure')}}
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="ColumnChart"
                                        :data="touristchartData"
                                        :key="reload"
                                        :options="chartOptions"
                                        style="width: 100%; height: 350px" />
                            </div>
                        </template>
                    </v-card>


                </template>

                <template>
                    <v-card class="pa-5" elevation="5">
                        <h1 class="headline mb-2 primary--text"
                            style="border-bottom: 1px solid grey">
                            {{$t('country_wise_customer_visit_per_month')}}
                        </h1>
                        <template>
                            <div id="app">
                                <GChart type="LineChart"
                                        :data="countrytouristchartData"
                                        :key="reload"
                                        :options="chartOptions"
                                        style="width: 100%; height: 350px" />
                            </div>
                        </template>
                    </v-card>


                </template>


                <template>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-card class="pa-5" elevation="5">
                                <h1 class="headline mb-2 primary--text"
                                    style="border-bottom: 1px solid grey">
                                    {{$t('performance_of_airport')}}
                                </h1>
                                <template>
                                    <div id="app">
                                        <GChart type="ColumnChart"
                                                :data="chartData"
                                                :key="reload"
                                                :options="chartOptions"
                                                style="height: 350px" />
                                    </div>
                                </template>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-card class="pa-5" elevation="5">
                                <h1 class="headline mb-2 primary--text"
                                    style="border-bottom: 1px solid grey">
                                    {{$t('monthly_performance_of_airport')}}
                                </h1>
                                <template>
                                    <div id="app">
                                        <GChart type="LineChart"
                                                :data="monthlyData"
                                                :options="chartOptions"
                                                :marker="marker"
                                                :key="reload"
                                                style="height: 350px" />
                                    </div>
                                </template>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
                <template>
                    <v-row>
                        <!--<v-col cols="12" sm="6">
                            <v-card color="#232620">
                                <v-app-bar color="rgba(0,0,0,0)" flat>
                                    <v-toolbar-title class="white--text title">Domestic Passenger</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-dialog max-width="1000px" v-model="dialogCreate">
                                        <template v-slot:activator="{ on }">
                                            <v-chip :success="success"
                                                    class="white--text clickbtn ml-3 mb-2 float-right"
                                                    color="#077dfa"
                                                    dark
                                                    v-on="on">
                                                Import Data
                                            </v-chip>
                                            <v-chip :success="success"
                                                    class="black--text clickbtn mb-2 float-right"
                                                    color="white"
                                                    dark
                                                    v-on="on">
                                                Add Domestic Passenger
                                            </v-chip>

                                        </template>
                                        <createDivisionForm :success="success"
                                                            @formResponse="onResponse" />
                                    </v-dialog>
                                    <v-dialog max-width="1000px" v-model="dialogEdit">
                                        <editDivisionForm :serviceID="editedData.serviceID"
                                                          :success="success"
                                                          @formResponse="onResponse"
                                                          v-if="dialogEdit" />
                                    </v-dialog>
                                    <v-dialog max-width="500px" v-model="dialogDelete">
                                        <deleteListData :deleteFormData="deleteFormData" @formResponse="onResponse"></deleteListData>
                                    </v-dialog>
                                </v-app-bar>
                                <v-data-table :headers="headers" :items="desserts" class="dattab">
                                    <template v-slot:item.glutenfree="{ item }">
                                        <v-simple-checkbox v-model="item.glutenfree"
                                                           color="cyan"></v-simple-checkbox>
                                    </template>
                                    <template v-slot:item.actions="">
                                        <v-icon @click="editItem(props.row)" class="mr-2" small> {{ svgpencil }}</v-icon>
                                        <v-icon @click="deleteItem(props.row)" small> {{ svgdelete }}</v-icon>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>-->

                        <v-col cols="12" sm="6">
                            <v-card class="pa-5" elevation="5">
                                <h1 class="headline mb-2 primary--text"
                                    style="border-bottom: 1px solid grey">
                                    {{$t('daily_domestic_passenger')}}
                                </h1>
                                <v-dialog max-width="1000px" v-model="dialogCreate">
                                    <template v-slot:activator="{ on }">
                                        <v-btn :success="success"
                                               class=" clickbtn mb-2 float-right"
                                               color="primary"
                                               dark
                                               v-on="on">
                                            {{$t('add_domestic_passenger')}}
                                        </v-btn>
                                    </template>
                                    <createDivisionForm :success="success"
                                                        @formResponse="onResponse" />
                                </v-dialog>
                                <v-dialog max-width="1000px" v-model="dialogEdit">
                                    <editDivisionForm :serviceID="editedData.serviceID"
                                                      :success="success"
                                                      @formResponse="onResponse"
                                                      v-if="dialogEdit" />
                                </v-dialog>

                                <v-dialog max-width="500px" v-model="dialogDelete">
                                    <deleteListData :deleteFormData="deleteFormData"
                                                    @formResponse="onResponse"></deleteListData>
                                </v-dialog>
                                <v-expansion-panels hover>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container fluid>
                                                <v-row align="start" class="mt-4">
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-autocomplete :items="airlineList"
                                                                        item-value="id"
                                                                        item-text="value"
                                                                        dense
                                                                        outlined
                                                                        v-model="serverParams.airlineName"
                                                                        :label="$t('airline_name')"
                                                                        clearable
                                                                        required></v-autocomplete>
                                                    </v-col>
                                                    <v-col class="float-right" cols="12" sm="6">
                                                        <v-autocomplete :items="['Arrival', 'Departure']"
                                                                        v-model="serverParams.arrivalDeparture"
                                                                        :label="$t('arrival/departure')"
                                                                        clearable
                                                                        dense
                                                                        outlined
                                                                        required></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-btn @click="searchInput"
                                                           small
                                                           color="primary"
                                                           dark>
                                                        <i class="material-icons">search</i>{{$t('search')}}
                                                    </v-btn>
                                                </v-row>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <!--:isLoading.sync="isLoading"-->

                                <vue-good-table :columns="columns"
                                                :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPageDropdown: [5,10, 15,20]
                                      }"
                                                :rows="rows"
                                                :totalRows="totalRecords"
                                                @on-column-filter="onColumnFilter"
                                                @on-page-change="onPageChange"
                                                @on-per-page-change="onPerPageChange"
                                                @on-sort-change="onSortChange"
                                                mode="remote">
                                    <template slot="table-column" slot-scope="props">
                                        <span>
                                            {{ $t(props.column.label) }}
                                        </span>
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'actions'">
                                            <v-icon @click="editItem(props.row)" class="mr-2" small>edit</v-icon>
                                            <v-icon @click="deleteItem(props.row)" small>delete</v-icon>
                                        </span>
                                    </template>
                                </vue-good-table>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-card class="pa-5" elevation="5">
                                <h1 class="headline mb-2 primary--text"
                                    style="border-bottom: 1px solid grey">
                                    {{$t('employee')}}
                                </h1>
                                <v-dialog max-width="1000px" v-model="edialogCreate">
                                    <template v-slot:activator="{ on }">
                                        <v-btn :success="success"
                                               class=" clickbtn mb-2 float-right"
                                               color="primary"
                                               dark
                                               v-on="on">
                                            {{$t('add_employee')}}
                                        </v-btn>
                                    </template>
                                    <createEmployeeForm :success="success"
                                                        @formResponse="oneResponse" />
                                </v-dialog>
                                <v-dialog max-width="1000px" v-model="edialogEdit">
                                    <editEmployeeForm :eserviceID="editedData.eserviceID"
                                                      :success="success"
                                                      @formResponse="oneResponse"
                                                      v-if="edialogEdit" />
                                </v-dialog>
                                <v-dialog max-width="500px" v-model="edialogDelete">
                                    <deleteListData :deleteFormData="deleteFormData"
                                                    @formResponse="oneResponse"></deleteListData>
                                </v-dialog>
                                <v-expansion-panels hover>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-container fluid>
                                                <v-row align="center" class="mt-3">
                                                    <v-col cols="12" md="6">
                                                        <v-text-field v-model="eserverParams.empName"
                                                                      :label="$t('employee_name')"
                                                                      type="string"
                                                                      dense
                                                                      outlined
                                                                      required></v-text-field>
                                                    </v-col>

                                                </v-row>
                                                <v-row align="center" justify="center">
                                                    <v-btn @click="searchInput"
                                                           small
                                                           color="primary"
                                                           dark>
                                                        <i class="material-icons">search</i>{{$t('search')}}
                                                    </v-btn>
                                                </v-row>
                                            </v-container>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                                <!--:isLoading.sync="isLoading"-->

                                <vue-good-table :columns="employeeColumns"
                                                :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPageDropdown: [5,10, 15,20]
                                      }"
                                                :rows="employeeRows"
                                                :totalRows="totalRecords"
                                                @on-column-filter="onColumnFilter"
                                                @on-page-change="onPageChange"
                                                @on-per-page-change="onPerPageChange"
                                                @on-sort-change="onSortChange"
                                                mode="remote">
                                    <template slot="table-column" slot-scope="props">
                                        <span>
                                            {{ $t(props.column.label) }}
                                        </span>
                                    </template>
                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'actions'">
                                            <v-icon @click="editEmployeeItem(props.row)" class="mr-2" small>edit</v-icon>
                                            <v-icon @click="deleteEmployeeItem(props.row)" small>delete</v-icon>
                                        </span>
                                    </template>
                                </vue-good-table>
                            </v-card>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-card class="pa-5" elevation="5">
                                <h1 class="headline mb-2 primary--text"
                                    style="border-bottom: 1px solid grey">
                                    {{$t('departure_flights')}}
                                </h1>
                                <template>
                                    <!--<div id="chart" ml="10">
                                        <apexchart type="pie"
                                                   height="350"
                                                   width="650"
                                                   align="left"
                                                   :options="chartsOptions"
                                                   :series="series"></apexchart>
                                    </div>-->
                                    <GChart type="PieChart"
                                            :options="options"
                                            :data="data"
                                            height="500"
                                            align="center" />
                                </template>
                            </v-card>
                        </v-col>



                    </v-row>
                </template>
            </v-container>
        </div>
    </div>
</template>
<script>
    import HoverCard from '@/components/Basic/HoverCard'
    import { mdiPencil, mdiDelete } from "@mdi/js";
    import axios from "axios";
    import { parseJwt } from "@/mixins/parseJwt";
    import AssociationDashboard from "../Dashboard/AssociationDashboard.vue";
    import createDivisionForm from "../DomesticPassenger/create.vue";
    import deleteListData from "../../../components/deleteModal.vue";
    import editDivisionForm from "../DomesticPassenger/edit.vue";
    import editEmployeeForm from "../Employee/edit.vue";
    import createEmployeeForm from "../Employee/create.vue";
    import publicAxios from "../../../simple-axios";
    import { mapGetters } from "vuex";

    export default {
        name: "BuddhaAir",
        components: {
            createDivisionForm,
            editDivisionForm,
            editEmployeeForm,
            deleteListData,
            AssociationDashboard,
            createEmployeeForm,
            HoverCard
        },
        computed: {
            ...mapGetters(["getSystemUserData"]),

            isAssociation() {
                return this.getSystemUserData.role === "Association";
            },
            AirportName() {
                return this.getSystemUserData.name;
            },
            isNepaliLanguage() {
                return this.$i18n.locale === "ne";
            },
            titles() {
                return [
                    {
                        name: this.$t('profile'),
                        link: "/branch",
                        icon: "mdi-robot"
                    }
                ]
            }
        },
        mixins: [parseJwt],
        watch: {
            dialog(val) {
                val || this.close();
            },
            success: {
                handler: function (val, oldVal) {
                    console.log(val, oldVal);
                    this.loadItems();
                },
                deep: true,
            },
        },
        data() {
            return {
                reload: 0,
                airlineList: [],
                data: [],
                options: {
                    height: 450,
                    is3d: true,
                },

                callbackResponse: {
                    timeout: 6000,
                },
                success: false,
                flightIncomingOutgoing: [],
                dialogEdit: false,
                edialogEdit: false,
                dialogDelete: false,
                edialogDelete: false,
                items: ["Foo", "Bar", "Fizz", "Buzz"],
                province: [],
                provinceId: [],
                airportData: [],
                deleteFormData: {},
                popout: true,
                svgpencil: mdiPencil,
                svgdelete: mdiDelete,
                dialogCreate: false,
                edialogCreate: false,
                slides: ["", "", "", ""],
                chartData: [],
                touristchartData: [],
                countrytouristchartData: [],
                //["Flights", "Incoming", "Outgoing"],
                //   ["Nepal Airlines", 15000, 12000],
                //   ["Buddha Air", 14230, 12000],
                //   ["Yeti Airlines", 13660, 15000],
                //   ["Guna Airlines", 7030, 9020],
                //   ["Himalaya Airlines", 12025, 11020],
                //   ["Saurya Airlines", 3040, 3504],
                //   ["Shree Airlines", 2100, 1420],
                //   ["Sita Air", 10000, 12300],
                monthlyData: [],
                //["Month", "Passenger"],
                //["Jan", 2000],
                //["Feb", 2170],
                //["Mar", 2060],
                //["Apr", 3030],
                //["May", 40250],
                //["Jun", 40400],
                //["Jul", 50000],
                //["Aug", 58000],
                //["Sep", 68000],
                //["Oct", 7000],
                //["Nov", 58000],
                //["Dec", 68000],
                series: [
                    56090,
                    44961,
                    30200,
                    44336,
                    20350,
                    56210,
                    44219,
                    30212,
                    44320,
                    20120,
                    1200,
                    2130,
                    1239,
                    1236,
                    2810,
                ],
                chartOptions: {
                    chart: {
                        title: "Company Performance",
                        subtitle: "Sales, Expenses, and Profit: 2014-2020",
                    },
                },
                chartsOptions: {
                    chart: {
                        width: 800,
                        type: "donut",
                    },
                    labels: [
                        "Pokhara",
                        "Kathmandu",
                        "Rajbiraj",
                        "Bhairahawa",
                        "Biratnagar",
                        "Bharatpur",
                        "Bhadrapur",
                        "Dhangadhi",
                        "Tumlingtar",
                        "Nepalgunj",
                        "Surkhet",
                        "Janakpur",
                        "Simara",
                        "Varanasi",
                    ],
                    responsive: [
                        {
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 500,
                                },
                                legend: {
                                    position: "bottom",
                                },
                            },
                        },
                    ],
                },
                columns: [
                    {
                        label: "s_no",
                        field: "countIndex",
                    },
                    {
                        label: 'airlines_name',
                        field: "airlineName",
                    },
                    {
                        label: 'arrival_departure',
                        field: "arrivalDeparture",
                    },
                    {
                        label: 'airplane_number',
                        field: "flightNumber",
                    },
                    {
                        label: 'arrival_departure_time',
                        field: "arrivalDepartureDateString",
                    },
                    {
                        label: 'number_of_person',
                        field: "numberOfPerson",
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false,
                    },
                ],
                employeeColumns: [
                    {
                        label: 's_no',
                        field: "countIndex",
                    },
                    {
                        label: 'employee_name',
                        field: "empName",
                    },
                    {
                        label: 'employee_phone',
                        field: "empPhone",
                    },
                    {
                        label: 'employee_address',
                        field: "empAddress",
                    },
                    {
                        label: 'employee_email',
                        field: "empEmail",
                    },
                    {
                        label: 'employee_type',
                        field: "empType",
                    },
                    {
                        label: 'actions',
                        field: "actions",
                        sortable: false,
                    },
                ],
                //chartPieData: [
                //    ["Language", "Blog"],
                //    ["PHP", 180],
                //    ["Laravel", 200],
                //    ["Html", 100],
                //    ["vue.js", 150]
                //],
                //chartpieOptions: {
                //    chart: {
                //        title: "Company Performance",
                //        subtitle: "blog in total: languages"
                //    }
                //},
                rows: [],
                employeeRows: [],
                totalRecords: 0,
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "asc",
                        },
                    ],
                    page: 1,
                    perPage: 5,
                },
                eserverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "employeeID",
                            type: "desc",
                        },
                    ],
                    page: 1,
                    perPage: 10,
                    empName: "",
                },
                user: [],
                editedIndex: -1,
                editedData: {
                    serviceID: "",
                    eserviceID: "",
                    serviceName: "",
                    serviceDescription: "",
                },
                desserts: [
                    {
                        sno: 1,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 60,
                        departureTime: "08:30 A.M",
                        arrivalTime: "10:00 A.M",
                    },
                    {
                        sno: 2,
                        airportName: "Yeti Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 40,
                        departureTime: "02:15 P.M",
                        arrivalTime: "3:45 P.M",
                    },
                    {
                        sno: 3,
                        airportName: "Guna Airlines",
                        arrivalDeparture: "Departure",
                        numberOfPerson: 20,
                        departureTime: "04:45 P.M",
                        arrivalTime: "06:25 P.M",
                    },
                    {
                        sno: 4,
                        airportName: "Saurya Airlines",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 15,
                        departureTime: "06:25 P.M",
                        arrivalTime: "08:05 P.M",
                    },
                    {
                        sno: 5,
                        airportName: "Buddha Air",
                        arrivalDeparture: "Arrival",
                        numberOfPerson: 20,
                        departureTime: "07:00 P.M",
                        arrivalTime: "08:30 P.M",
                    },
                ],
                headers: [
                    { text: "S.No", value: "sno" },
                    {
                        text: "Airport Name",
                        align: "start",
                        sortable: false,
                        value: "airportName",
                    },
                    { text: "Arrival Departure", value: "arrivalDeparture" },
                    { text: "Number of Person", value: "numberOfPerson" },
                    { text: "Departure Time", value: "departureTime" },
                    { text: "Arrival Time", value: "arrivalTime" },
                    { text: "Actions", value: "actions", sortable: false },
                ],
            };
        },
        mounted() {
            this.getCompleteData();
        },
        destroyed() {
            console.log(this); // There's practically nothing here!
        },
        methods: {
            getCompleteData() {
                this.loadItems();
                this.getAirportIncomingOutgoing();
                this.getBarAirportPerformanceIncomingandOutgoing();
                this.getBarMonthlyTouristIncomingandOutgoing();
                this.getCountryWiseCustomerPerMonth();
                this.getBarAirportMonthlyPerformance();
                this.getAirportDetail();
                this.getPieAirportMonthlyPerformance();
                this.formData();
                this.BarAirportPerformanceIncomingandOutgoing();
            },
            onResponse(data) {
                if (data.message) {
                    (this.dialogDelete = false), (this.dialogCreate = false);
                    this.dialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true;
                    this.loadItems();
                    this.reloadPage();
                } else {
                    this.dialogCreate = false;
                    this.dialogEdit = false;
                    this.dialogDelete = false;
                }
            },
            oneResponse(data) {
                if (data.message) {
                    (this.edialogDelete = false), (this.edialogCreate = false);
                    this.edialogEdit = false;
                    this.callbackResponse = data;
                    this.snackbar = true;
                    this.loadItems();
                    this.reloadPage();
                } else {
                    this.edialogCreate = false;
                    this.edialogEdit = false;
                    this.edialogDelete = false;
                }
            },
            async formData() {
                axios
                    .get("Airport/DDLAirlineListAsync")
                    .then((response) => {
                        this.airlineList = response.data;
                    })
                    .catch((error) => console.log(error.response));
            },
            editItem(props) {
                this.editedData.serviceID = props.id;
                this.dialogEdit = true;
            },
            editEmployeeItem(props) {

                this.editedData.eserviceID = props.employeeID;
                this.edialogEdit = true;
            },
            deleteItem(props) {

                this.dialogDelete = true;
                this.deleteFormData.url =
                    "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
            },
            deleteEmployeeItem(props) {
                this.edialogDelete = true;
                this.deleteFormData.url =
                    "Employee/DeleteEmployeeDetailByIDAsync/" + props.employeeID;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedData = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },
            searchInput() {
                this.loadItems();
            },
            async insertLog() {
                try {
                    const { data } = await axios.get('Airport/InsertDataFromImmigrationAsync')
                    this.ReportCats = data
                } catch (e) {
                    console.log(e)
                }
            },
            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [
                        {
                            type: params[0].type,
                            field: params[0].field,
                        },
                    ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            // load items is what brings back the rows from server
            async loadItems() {
                console.log(this.tokenData);
                // eslint-disable-next-line no-console
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    AirportName: this.serverParams.airlineName,
                    Groupby: this.serverParams.arrivalDeparture,
                };
                let eparam = {
                    PageSize: this.eserverParams.perPage,
                    PageNo: this.eserverParams.page,
                    OrderType: this.eserverParams.sort[0].type,
                    OrderBy: this.eserverParams.sort[0].field,
                    EmpName: this.eserverParams.empName,
                };
                axios
                    .post("Employee/GetEmployeeDetailListAsync", eparam)
                    .then((response) => {
                        this.loading = false;
                        this.employeeRows = response.data.data;
                        console.log("response.data", response.data);
                        console.log("this.employeeRows", this.employeeRows);
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                axios
                    .post("Airport/GetDomesticPassengerDetailListAsync", param)
                    .then((response) => {
                        this.rows = response.data.data;
                        console.log("this.rows", this.rows);
                        this.totalRecords = response.data.totalCount;
                    })
                    .catch((err) => {
                        console.log(err);
                    });


            },
            async getAirportDetail() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportDetailsByBranchID"
                );
                this.airportData = data;
                console.log("this.airportData", this.airportData);
            },
            //async getBarAirportPerformanceIncomingandOutgoing() {
            //    setInterval(async () => {
            //        const { data } = await publicAxios.get('Airport/GetAirportAirlineDetailsByBranchAsync')
            //        let formatedData = this.getChartData(data)
            //        this.chartData = formatedData
            //    }, 4000);

            //},
            async getBranchEmployeeDetails() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportEmployeeDetails/0"
                );
                this.employeeRows = data;
                console.log("this.employeeRows", this.employeeRows);
            },
            async getBarAirportPerformanceIncomingandOutgoing() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportAirlineDetailsByBranchAsync"
                );
                let formatedData = this.getChartData(data);
                this.chartData = formatedData;
                console.log('this.AirportAirlines', this.chartData);
            },
            async getBarMonthlyTouristIncomingandOutgoing() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportMonthlyTouristArrivalDeparture"
                );
                let formatedData = this.getChartData(data);
                this.touristchartData = formatedData;
                console.log('this.touristchartData', this.touristchartData);
            },
            async getCountryWiseCustomerPerMonth() {
                const { data } = await publicAxios.get(
                    "Airport/GetCountryWiseCustomerPerMonth"
                );
                let formatedData = this.getChartData(data);
                this.countrytouristchartData = formatedData;
                console.log('this.countrytouristchartData', this.countrytouristchartData);
            },
            async getBarAirportMonthlyPerformance() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportPassengerCountAsync"
                );
                let formatedData = this.getChartData(data);
                this.monthlyData = formatedData;
            },
            async getPieAirportMonthlyPerformance() {
                const { data } = await publicAxios.post(
                    "Airport/GetAirportDepartureFlights"
                );
                let formatedData = this.getChartData(data);
                this.data = formatedData;
            },

            //IncomingandOutgoing: function () {
            //    const { data } = await publicAxios.get("Airport/GetAirportFlightCountByBranchAsync")
            //    this.flightIncomingOutgoing = data
            //    console.log('this.flightIncomingOutgoing', this.flightIncomingOutgoing)
            //},
            async getAirportIncomingOutgoing() {
                const { data } = await publicAxios.get(
                    "Airport/GetAirportFlightCountByBranchAsync"
                );
                this.flightIncomingOutgoing = data;
                console.log('this.flightIncomingOutgoing', this.flightIncomingOutgoing);
            },
            reloadPage() {
                this.getCompleteData();
                this.reload += 1;
                //this.$router.go();
            },
            getChartData(data) {
                console.log(data);
                let arr = [];
                arr[0] = Object.keys(data[0]);
                for (let i = 1; i < data.length + 1; i++) {
                    arr[i] = Object.values(data[i - 1]);
                }
                return arr;
            },
        },

        created() {
            //this.getAirportIncomingOutgoing()
            this.getBranchEmployeeDetails();
        }
    };
</script>
<style>
    @media screen and (min-width:1903px) {
        #subboxtitle {
            color: white;
            font-weight: bold;
        }
    }

    @media screen and (max-width:1903px) {
        #subboxtitle {
            color: white;
            font-weight: bold;
        }
    }

    @media screen and (max-width:1263px) {
        .col-sm-3 {
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;
        }

        .col-sm-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 50%;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #subtitle {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width:599px) {
        .col-sm-3 {
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }

        #subtitle {
            margin-bottom: 30px;
        }
    }

    @media screen and (max-width:491px) {
        .col-sm-3 {
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }

        .v-application .subtitle-1 {
            font-size: 12px !important;
            font-weight: normal;
            letter-spacing: 0.009375em !important;
            line-height: 1.75rem;
            font-family: "Roboto", sans-serif !important;
        }
    }
</style>
