<template>
    <v-card>

        <v-toolbar dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('add_employee')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <form>
                    <v-row>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="editedData.empName"
                                          :label="$t('employee_name')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="editedData.empAddress"
                                          :label="$t('employee_address')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field v-model="editedData.empEmail"
                                          :label="$t('employee_email')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-text-field v-model="editedData.empPhone"
                                          :label="$t('employee_phone')"
                                          type="string"
                                          dense
                                          outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">

                            <v-autocomplete :items="currentEmployeeTypes"
                                            item-value="id"
                                            item-text="value"
                                            dense
                                          outlined
                                            v-model="editedData.empType"
                                            :label="$t('employee_type')"
                                            required></v-autocomplete>
                        </v-col>



                    </v-row>
                </form>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="submit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    // import { required } from "vuelidate/lib/validators";
    import axios from "axios";


    export default {
        name: "createAirportForm",
        props: ["success"],
        computed: {},
        watch: {
            updated: {
                handler: function (val) {
                    if (this.updated) {
                        this.updated = false;
                        this.$emit("formResponse", val);
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                employeeList: [],
                state: [],
                currentEmployeeTypes: [],
                EmployeeTypes: [
                    { id: "Permanent", value: "Permanent" },
                    { id: "Temporary", value: "Temporary" }],
                dateMenu: false,
                timeMenu: false,
                province: [],
                show: false,
                updated: false,
                editedData: {},
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            };
        },
        validations: {},
        destroyed() {
        },
        mounted() {
            this.currentEmployeeTypes = [];
            this.currentEmployeeTypes = this.EmployeeTypes.filter(function (data) {
                return data;
            });
            this.$forceUpdate();
            this.formData();
        },
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            async formData() {
                axios.get("Airport/DDLemployeeListAsync").then(response => {
                    this.employeeList = response.data;
                }).catch(error => console.log(error.response))
            },
            submit() {
                this.$v.$touch();
               
                if (this.$v.$invalid) {
                    this.submitStatus = "ERROR";
                } else {
                    axios.post("Employee/InsertIntoEmployeeDetailAsync", this.editedData).then(response => {
                        if (response.data.success) {
                            this.$v.$reset();
                            this.editedData = {}
                            this.servicesData.data = response.data
                            this.servicesData.message = "Passenger added Succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                            
                        }
                    }).catch(response => {
                        this.servicesData.data = response.data
                        this.servicesData.message = "Error Adding Service, Please Contact Admin"
                        this.servicesData.color = "error"
                    });
                }
            },
            clear() {
                this.$v.$reset();
                this.editedData.VehicleType = "";
                this.editedData.VehicleCategory = "";
            }
        }
    };
</script>

<style scoped>
</style>