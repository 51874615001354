<template>
  <div class="home" style="margin-left:30px; margin-top: 30px">
    <v-container fluid class="mt-4">
        <v-row>
            <v-col cols="12" sm="4">
                <v-card color="#006400" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            {{$t('total_no_of_flight')}}
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>
                                        <!-- <v-list-item-subtitle class="subtitle-1 white--text" style="font-size:19px"
                                            >742,824</v-list-item-subtitle
                                          >
                                          <v-list-item-subtitle
                                            class="white--text"
                                            style="font-size: 11px"
                                            >CHANGE TO</v-list-item-subtitle
                                          > -->
                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">
                                            {{
                          totalNoAirportAirlines.flight.TotalFlighInMonth
                                            }}
                                        </v-list-item-subtitle>

                                        <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>
                                          <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                          <span class="white--text">3211</span><br />
                                          <span class="white--text">(2.16%)</span>-->
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card color="#FFA500" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            {{$t('total_no_of_airports')}}
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight_land</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>
                                        <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                            >914,175</v-list-item-subtitle
                                          >
                                          <v-list-item-subtitle
                                            class="white--text"
                                            style="font-size: 11px"
                                            >CHANGE TO</v-list-item-subtitle
                                          >
                                          <v-list-item-subtitle
                                            class="white--text mb-5"
                                            style="gont-size: 11px"
                                            >PREVIOUSE MONTH</v-list-item-subtitle
                                          > -->

                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">
                                            {{
                          totalNoAirportAirlines.airport.AirportCount
                                            }}
                                        </v-list-item-subtitle>

                                        <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>
                                          <v-icon color="red" class="mt-2 mr-2">fas fa-sort-down</v-icon>
                                          <span class="white--text">1697</span><br />
                                          <span class="white--text">(0.12%)</span>-->
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" sm="4">
                <v-card color="#077dfa" elevation="5">
                    <v-row>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div>
                                        <v-list-item-subtitle class="subtitle-1 mb-14 white--text">
                                            {{$t('total_no_of_airlines')}}
                                        </v-list-item-subtitle>
                                        <v-icon color="white" x-large>flight_takeoff</v-icon>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" sm="6">
                            <v-list-item three-line>
                                <v-list-item-content class="text-right">
                                    <div>
                                        <!-- <v-list-item-subtitle class="subtitle-1 white--text"
                                            >415,721</v-list-item-subtitle
                                          >
                                          <v-list-item-subtitle
                                            class="white--text"
                                            style="font-size: 11px"
                                            >CHANGE TO</v-list-item-subtitle
                                          >
                                          <v-list-item-subtitle
                                            class="white--text mb-5"
                                            style="gont-size: 11px"
                                            >PREVIOUSE MONTH</v-list-item-subtitle
                                          > -->
                                        <v-list-item-subtitle class="white--text"
                                                              style="font-size: 26px">
                                            {{
                          totalNoAirportAirlines.aviation.AirportCount
                                            }}
                                        </v-list-item-subtitle>

                                        <!--<v-list-item-subtitle class="white--text mb-5"
                                                                style="font-size: 14px">Actual</v-list-item-subtitle>
                                          <v-icon color="green" class="mt-2 mr-2">fas fa-sort-up</v-icon>
                                          <span class="white--text">1514</span><br />
                                          <span class="white--text">(4.16%)</span>-->
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <!-- Airports-->
            <!-- New -->
            <v-col cols="4"
                   v-for="title in snackbar.dashboardItems"
                   v-bind:key="title.link"
                   class="viewCard">
                <router-link :to="title.link">
                    <HoverCard :cardData="title"></HoverCard>

                    <!--<v-card :elevation="10"
                            class="blue darken-1 text-center"
                            height="100"
                            width="100%">
                        <v-list-item three-line link>
                            <v-list-item-content>
                                <p id="subboxtitle">
                                    {{title.name}}
                                </p>
                                <v-list-item-subtitle class="white--text header-6 mt-1">
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>-->
                </router-link>
            </v-col>

            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('today_performance_of_airports')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="airportIncomingOutgoingBar"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('monthly_performance_of_airports')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="monthlyAirportIncomingOutgoingBar"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('airport_list')}}
                    </h1>

                    <v-sheet id="slider">
                        <v-slide-group v-model="pendingSlider"
                                       active-class="success"
                                       show-arrows>
                            <v-slide-item v-for="(airport, index) in airportListData"
                                          :key="index">
                                <v-container>
                                    <v-card class="mt-5"
                                            id="slider-card"
                                            max-height="550"
                                            max-width="382.2"
                                            :elevation="24"
                                            style="background:white">
                                        <img :src="airport.logo"
                                             style="width: 100%; height: 200px; object-fit: fill; border: 3px solid black;" />

                                        <v-card-title style="font-size:28px;">
                                            {{
                        airport.name
                                            }}
                                        </v-card-title>

                                        <v-card-subtitle class="pb-0 black--text" align="left">
                                            <strong>{{ airport.address }}</strong>
                                        </v-card-subtitle>
                                        <v-card-text class="text--primary">
                                            <div id="scroller"
                                                 style="overflow-y: scroll; max-height: 170px">
                                                Buddha Air, a household name in the Nepali aviation
                                                sector has become the trademark of trust and safety in
                                                23 years of its service. Our journey had humbly begun
                                                on October 11, 1997, with a single aircraft. Today we
                                                fly to at least 15 national and international
                                                destinations a day with our 14 state-of-art
                                                aircraft.Buddha Air, a household name in the Nepali
                                                aviation sector has become the trademark of trust and
                                                safety in 23 years of its service. Our journey had
                                                humbly begun on October 11, 1997, with a single
                                                aircraft. Today we fly to at least 15 national and
                                                international destinations a day with our 14
                                                state-of-art aircraft.
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <!--<button :hotelId="hotel.branchID" @click="$router.push('/hotel/hotelDetails')" class=" white--text cyan darken-2" dark style="padding:5px; border-radius:5px">Details</button>-->
                                            <router-link style="background-color:deepskyblue; padding:5px; color:#fff;text-decoration:none;border-radius:5px;"
                                                         :to="{
                            name: 'AirportDetailDashboard',
                            params: { airportID: airport.branchID },
                          }"> {{$t('table.details')}}</router-link>
                                        </v-card-actions>
                                    </v-card>
                                </v-container>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-card>
            </v-col>

            <!-- Airports-->
            <!--Airlines-->
            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('today_performance_of_airlines')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="airlinesIncomingOutgoingBar"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('monthly_performance_of_airlines')}}
                    </h1>
                    <template>
                        <div id="app">
                            <GChart type="ColumnChart"
                                    :data="monthlyAirlinesIncomingOutgoingBar"
                                    :options="chartOptions"
                                    style="height: 350px" />
                        </div>
                    </template>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12">
                <v-card class="pa-5" elevation="5">
                    <h1 class="headline mb-2 primary--text"
                        style="border-bottom: 1px solid grey">
                        {{$t('list_of_airlines')}}
                    </h1>

                    <v-sheet id="slider">
                        <v-slide-group v-model="pendingSlider"
                                       active-class="success"
                                       show-arrows>
                            <v-slide-item v-for="(airLines, index) in airlinesListData"
                                          :key="index">
                                <v-container>
                                    <v-card class="mt-5"
                                            id="slider-card"
                                            max-height="550"
                                            max-width="382.2"
                                            :elevation="24"
                                            style="background:white">
                                        <img :src="airLines.logo"
                                             style="width: 100%; height: 200px; object-fit: fill; border: 3px solid black;" />

                                        <v-card-title style="font-size:28px;">
                                            {{
                        airLines.name
                                            }}
                                        </v-card-title>

                                        <v-card-subtitle class="pb-0 black--text" align="left">
                                            <strong>{{ airLines.address }}</strong>
                                        </v-card-subtitle>
                                        <v-card-text class="text--primary">
                                            <div id="scroller"
                                                 style="overflow-y: scroll; max-height: 170px">
                                                Buddha Air, a household name in the Nepali aviation
                                                sector has become the trademark of trust and safety in
                                                23 years of its service. Our journey had humbly begun
                                                on October 11, 1997, with a single aircraft. Today we
                                                fly to at least 15 national and international
                                                destinations a day with our 14 state-of-art
                                                aircraft.Buddha Air, a household name in the Nepali
                                                aviation sector has become the trademark of trust and
                                                safety in 23 years of its service. Our journey had
                                                humbly begun on October 11, 1997, with a single
                                                aircraft. Today we fly to at least 15 national and
                                                international destinations a day with our 14
                                                state-of-art aircraft.
                                            </div>
                                        </v-card-text>
                                        <v-card-actions class="justify-end">
                                            <!--<button :hotelId="hotel.branchID" @click="$router.push('/hotel/hotelDetails')" class=" white--text cyan darken-2" dark style="padding:5px; border-radius:5px">Details</button>-->
                                            <router-link style="background-color:deepskyblue; padding:5px; color:#fff;text-decoration:none;border-radius:5px;"
                                                         :to="{
                            name: 'AirlinesDetailDashboard',
                            params: { airlinesID: airLines.branchID },
                          }">{{$t('table.details')}}</router-link>
                                        </v-card-actions>
                                    </v-card>
                                </v-container>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </v-card>
            </v-col>

            <!--Airlines-->
        </v-row>
    </v-container>
  </div>
</template>

<script>
    import HoverCard from '@/components/Basic/HoverCard'
import { mdiPencil, mdiDelete } from "@mdi/js";
import axios from "axios";
import publicAxios from "axios";
// import createDivisionForm from "../DomesticPassenger/create.vue";

export default {
  name: "Home",

  components: {
    // createDivisionForm
      HoverCard
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    success: {
      handler: function(val, oldVal) {
        console.log(val, oldVal);
        this.loadItems();
      },
      deep: true,
    },
  },
  data() {
    return {
      totalNoAirportAirlines: [],
      airportListData: [],
      airlinesListData: [],
      airportIncomingOutgoingBar: [],
      monthlyAirportIncomingOutgoingBar: [],
      airlinesIncomingOutgoingBar: [],
        monthlyAirlinesIncomingOutgoingBar: [],
        snackbar: {
            formModal: false,
            color: '',
            snackbar: false,
            text: '',
            timeout: 6000,
            drawer: null,
            drawerRight: null,
            right: false,
            createFormComponent: false,
            editFormComponent: false,
            dialogDelete: false,
            dashboardItems: [
                {
                    name: "Airport Manager Registration",
                    link: "/CaanRegristrationForm",
                    icon:"mdi-bow-tie"
                },
                {
                    name: "Airports and Airlines Filter",
                    link: "/CaanFilterPage",
                    icon:"mdi-filter"
                },
                {
                    name: "Reports",
                    link: "/CaanReportPage",
                    icon:"mdi-file-document"
                },
            ],
            dynamicComponent: {
                current: 'AirDestinationForm'
            },
            breadcrumbsItems: [
                {
                    text: "Dashboard",
                    disabled: true,
                    to: "/dashboard",
                    exact: true
                }],
        },
      titles: [
        {
          name: "Generate Report",
          link: "/report",
        },
        {
          name: "Profile",
          link: "/branch",
        },
        {
          name: "DomesticPassenger",
          link: "/Airport/DomesticPassenger",
        },
      ],
      branchInformation: [],
      roomCount: "",

      dialogCreate: false,
      svgpencil: mdiPencil,
      svgdelete: mdiDelete,
      slides: ["", "", "", ""],
      chartData: [
        ["Flights", "Incoming", "Outgoing"],
        ["Nepal Airlines", 15000, 12000],
        ["Buddha Air", 14230, 12000],
        ["Yeti Airlines", 13660, 15000],
        ["Guna Airlines", 7030, 9020],
        ["Himalaya Airlines", 12025, 11020],
        ["Saurya Airlines", 3040, 3504],
        ["Shree Airlines", 2100, 1420],
        ["Sita Air", 10000, 12300],
      ],
      monthlyData: [
        ["Month", "Passenger"],
        ["Jan", 2000],
        ["Feb", 2170],
        ["Mar", 2060],
        ["Apr", 3030],
        ["May", 40250],
        ["Jun", 40400],
        ["Jul", 50000],
        ["Aug", 58000],
        ["Sep", 68000],
        ["Oct", 7000],
        ["Nov", 58000],
        ["Dec", 68000],
      ],
      chartOptions: {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2020",
        },
      },
      series: [
        56090,
        44961,
        30200,
        44336,
        20350,
        56210,
        44219,
        30212,
        44320,
        20120,
      ],
      series1: [200, 250, 210, 190, 230, 240, 120, 220, 160, 150],
      chartsOptions: {
        chart: {
          width: 800,
          type: "donut",
        },
        labels: [
          "Buddha Air",
          "Nepal Airlines",
          "Yeti Airlines",
          "Guna Airlines",
          "Himalaya Airlines",
          "Saurya Airlines",
          "Shree Airlines",
          "Sita Air",
          "Tara Air",
          "Summit Airlines",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 500,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      desserts: [
        {
          sno: 1,
          airportName: "Buddha Air",
          arrivalDeparture: "Departure",
          numberOfPerson: 60,
          departureTime: "08:30 A.M",
          arrivalTime: "10:00 A.M",
        },
        {
          sno: 2,
          airportName: "Yeti Airlines",
          arrivalDeparture: "Arrival",
          numberOfPerson: 40,
          departureTime: "02:15 P.M",
          arrivalTime: "3:45 P.M",
        },
        {
          sno: 3,
          airportName: "Guna Airlines",
          arrivalDeparture: "Departure",
          numberOfPerson: 20,
          departureTime: "04:45 P.M",
          arrivalTime: "06:25 P.M",
        },
        {
          sno: 4,
          airportName: "Saurya Airlines",
          arrivalDeparture: "Arrival",
          numberOfPerson: 15,
          departureTime: "06:25 P.M",
          arrivalTime: "08:05 P.M",
        },
        {
          sno: 5,
          airportName: "Buddha Air",
          arrivalDeparture: "Arrival",
          numberOfPerson: 20,
          departureTime: "07:00 P.M",
          arrivalTime: "08:30 P.M",
        },
      ],
      headers: [
        { text: "S.No", value: "sno" },
        {
          text: "Airport Name",
          align: "start",
          sortable: false,
          value: "airportName",
        },
        { text: "Arrival Departure", value: "arrivalDeparture" },
        { text: "Number of Person", value: "numberOfPerson" },
        { text: "Departure Time", value: "departureTime" },
        { text: "Arrival Time", value: "arrivalTime" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      files: [
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
          action: "Open",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "<strong>Ali Connors</strong>",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
          action: "Open",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "<strong>Ali Connors</strong>",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
          action: "Open",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "<strong>Ali Connors</strong>",
        },
        {
          avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
          action: "Open",
          headline: "Brunch this weekend?",
          subtitle: `I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
          title: "<strong>Ali Connors</strong>",
        },
      ],
    };
  },
  mounted() {
    this.formData();
  },
  methods: {
    async getTotalFlightAirportAirlines() {
      const { data } = await publicAxios.get(
        "Airport/GetAllAirportFlightCountAsync"
      );
      this.totalNoAirportAirlines = data;
      console.log(
        "this.getTotalFlightAirportAirlines",
        this.totalNoAirportAirlines
      );
    },
    async getAirportListData() {
      const { data } = await publicAxios.post(
        "Airport/GetAllAiportDetailsListAsync"
      );
      this.airportListData = data;
      console.log("this.airportListsData", this.airportListData);
    },
    async getAirlinesListData() {
      const { data } = await publicAxios.post(
        "Airport/GetAllAviationDetailsListAsync"
      );
      this.airlinesListData = data;
      console.log("this.airlinesListData", this.airlinesListData);
    },
    async getAirportIncomingOutgoingBar() {
      const { data } = await publicAxios.get(
        "Airport/GetAllAirportArrivalDepartureCountDetailsAsync"
      );
      let formatedData = this.getChartData(data);
      this.airportIncomingOutgoingBar = formatedData;
      console.log(
        "this.AirportIncomingOutgoingBar",
        this.airportIncomingOutgoingBar
      );
    },
    async getMonthlyAirportIncomingOutgoingBar() {
      const { data } = await publicAxios.get(
        "Airport/GetAllMonthlyAirportArrivalDepartureCountDetailsAsync"
      );
      let formatedData = this.getChartData(data);
      this.monthlyAirportIncomingOutgoingBar = formatedData;
      console.log(
        "this.monthlyAirportIncomingOutgoingBar",
        this.monthlyAirportIncomingOutgoingBar
      );
    },

    async getAirlinesIncomingOutgoingBar() {
      const { data } = await publicAxios.get(
        "Airport/GetAllAviationArrivalDepartureCountDetailsAsync"
      );
      let formatedData = this.getChartData(data);
      this.airlinesIncomingOutgoingBar = formatedData;
      console.log(
        "this.AirlinesIncomingOutgoingBar",
        this.airlinesIncomingOutgoingBar
      );
    },
    async getMonthlyAirlinesIncomingOutgoingBar() {
      const { data } = await publicAxios.get(
        "Airport/GetAllAviationMonthlyArrivalDepartureCountDetailsAsync"
        );
        console.log(
            "this.jaa",
            data
        );



      let formatedData = this.getChartData(data);
      this.monthlyAirlinesIncomingOutgoingBar = formatedData;
      console.log(
        "this.monthlyAirlinesIncomingOutgoingBar",
        this.monthlyAirlinesIncomingOutgoingBar
      );
    },

    getChartData(data) {
      console.log(data);
      let arr = [];
      arr[0] = Object.keys(data[0]);
      for (let i = 1; i < data.length + 1; i++) {
        arr[i] = Object.values(data[i - 1]);
      }
      return arr;
    },
    async formData() {
      const availableCount = await axios.get("Hotel/RoomStatusCountAsync", {
        params: {
          ShowCount: true,
        },
      });
      this.roomCount = availableCount.data;
    },
    onResponse(data) {
      if (data.message) {
        (this.dialogDelete = false), (this.dialogCreate = false);
        this.dialogEdit = false;
        this.callbackResponse = data;
        this.snackbar = true;
        this.loadItems();
      } else {
        this.dialogCreate = false;
        this.dialogEdit = false;
        this.dialogDelete = false;
      }
    },
    editItem(props) {
      this.editedData.serviceID = props.id;
      this.dialogEdit = true;
    },
    deleteItem(props) {
      this.dialogDelete = true;
      this.deleteFormData.url =
        "Airport/DeleteDomesticPassengerByIDAsync/" + props.id;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedData = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    searchInput() {
      this.loadItems();
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // load items is what brings back the rows from server
    async loadItems() {
      console.log(this.tokenData);
      // eslint-disable-next-line no-console
      let param = {
        PageSize: this.serverParams.perPage,
        PageNo: this.serverParams.page,
        OrderType: this.serverParams.sort[0].type,
        OrderBy: this.serverParams.sort[0].field,
      };
      axios
        .post("Airport/GetDomesticPassengerDetailListAsync", param)
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.totalCount;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getTotalFlightAirportAirlines();
    this.getAirportListData();
    this.getAirlinesListData();
    this.getAirportIncomingOutgoingBar();
    this.getMonthlyAirportIncomingOutgoingBar();
    this.getAirlinesIncomingOutgoingBar();
    this.getMonthlyAirlinesIncomingOutgoingBar();
  },
};
</script>
<style scoped>
.datatab {
  background: #232620;
}


/*    @media screen and (max-width:1903px) {
        #slider-card {
            width: 328px;
        }
        #table {
            font-size: 12px !important;
        }
    }
    @media screen and (max-width: 1329px) {
        #slider-card {
            width: 362px;
        }

        .col-sm-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-top:10px;
        }

        .col-sm-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    @media screen and (max-width:1263px) {
        #slider-card {
            width: 362px;
        }
    }

    @media screen and (max-width:980px) {
        #slider-card {
            width: 310px;
        }
    }

    @media screen and (max-width:910px) {
        #slider-card {
            width: 310px;
        }
    }

    @media screen and (max-width:876px) {
        #slider-card {
            width: 280px;
        }
    }

    @media screen and (max-width:815px) {
        #slider-card {
            width: 250px;
        }
    }

    @media screen and (max-width:756px) {
        #slider-card {
            width: 550px;
        }
    }*/



    @media screen and (min-width:1903px) {
        #subboxtitle {
            margin-top: 20px;
            color: white;
        }

        #passengers {
            display: none;
        }
    }

    @media screen and (max-width:1903px) {
        #subboxtitle {
            margin-top: 20px;
            color: white;
        }

        #passengers {
            display: none;
        }
    }


    @media screen and (max-width:1903px) {
        #slider-card {
            width: 328px;
        }

        #table {
            font-size: 12px !important;
        }
    }

    @media screen and (max-width: 1329px) {
        #slider-card {
            width: 362px;
        }

        .col-sm-4 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            margin-top: 10px;
        }

        .col-sm-6 {
            -webkit-box-flex: 0;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width:1263px) {
        #slider-card {
            width: 362px;
        }
    }
    @media screen and (max-width:1049px) {
        #slider-card {
            width: 310px;
        }
    }

    @media screen and (max-width:980px) {
        #slider-card {
            width: 310px;
        }
    }

    @media screen and (max-width:945px) {
        #slider-card {
            width: 280px;
        }
    }
/*    @media screen and (max-width:910px) {
        #slider-card {
            width: 310px;
        }
    }*/

    @media screen and (max-width:876px) {
        #slider-card {
            width: 250px;
        }
    }

    @media screen and (max-width:815px) {
        #slider-card {
            width: 550px;
        }
    }

    @media screen and (max-width:756px) {
        #slider-card {
            width: 550px;
        }
    }
    @media screen and (max-width:676px) {
        #slider-card {
            width: 350px;
        }
    }


    @media screen and (max-width:655px) {
        #slider-card {
            width: 300px;
        }
    }

    @media screen and (max-width:589px) {
        #slider-card {
            width: 250px;
        }
    }

    
</style>
